
/** border radius **/
.br-2{
    border-radius: 2px;
    -moz-border-radius: 2px;
    -khtml-border-radius: 2px;
    -webkit-border-radius: 2px;
}
.br-3{
    border-radius: 3px;
    -moz-border-radius: 3px;
    -khtml-border-radius: 3px;
    -webkit-border-radius: 3px;
}
.br-5{
    border-radius: 5px;
    -moz-border-radius: 5px;
    -khtml-border-radius: 5px;
    -webkit-border-radius: 5px;
}
.br-7{
    border-radius: 7px;
    -moz-border-radius: 7px;
    -khtml-border-radius: 7px;
    -webkit-border-radius: 7px;
}
.br-10{
    border-radius: 10px;
    -moz-border-radius: 10px;
    -khtml-border-radius: 10px;
    -webkit-border-radius: 10px;
}
.br-15{
    border-radius: 15px;
    -moz-border-radius: 15px;
    -khtml-border-radius: 15px;
    -webkit-border-radius: 15px;
}
.br-20{
    border-radius: 20px;
    -moz-border-radius: 20px;
    -khtml-border-radius: 20px;
    -webkit-border-radius: 20px;
}
.br-25{
    border-radius: 25px;
    -moz-border-radius: 25px;
    -khtml-border-radius: 25px;
    -webkit-border-radius: 25px;
}
.br-30{
    border-radius: 30px;
    -moz-border-radius: 30px;
    -khtml-border-radius: 30px;
    -webkit-border-radius: 30px;
}
.br-40{
    border-radius: 40px;
    -moz-border-radius: 40px;
    -khtml-border-radius: 40px;
    -webkit-border-radius: 40px;
}
.br-50{
    border-radius: 50px;
    -moz-border-radius: 50px;
    -khtml-border-radius: 50px;
    -webkit-border-radius: 50px;
}