.bg-transparent {
    background: transparent
}
.bg-fff {
    background: #fff
}
.bg-fafafa{
	background: #fafafa;
}
.bg-eee {
    background: #eee
}
.bg-ddd {
    background: #ddd
}
.bg-ccc {
    background: #ccc
}
.bg-bbb {
    background: #bbb
}
.bg-aaa {
    background: #aaa
}
.bg-999 {
    background: #999
}
.bg-777 {
    background: #777
}
.bg-555 {
    background: #555
}
.bg-333 {
    background: #333
}
.bg-111 {
    background: #111
}
.bg-000 {
    background: #000;
}

.txt-fff {
    color: #fff !important
}
.txt-eee {
    color: #eee !important
}
.txt-ddd {
    color: #ddd !important
}
.txt-ccc {
    color: #ccc !important
}
.txt-bbb {
    color: #bbb !important
}
.txt-aaa {
    color: #aaa !important
}
.txt-999 {
    color: #999 !important
}
.txt-777 {
    color: #777 !important
}
.txt-555 {
    color: #555 !important
}
.txt-333 {
    color: #333 !important
}
.txt-111 {
    color: #111 !important
}
.txt-000 {
    color: #000 !important
}