/* 1px full border for app*/

.b1-red{
	border: 1px solid red ;
}
.b1-avw-blue{
	border: 1px solid #439ECA ;
}

.b1-avw-dark-green{
	border: 1px solid #42afbc;/*#ebf9fa;*/
}
.bt1-avw-dark-green{
	border-top: 1px solid #42afbc;/*#ebf9fa;*/
}
.bb1-avw-dark-green{
	border-bottom: 1px solid #42afbc;/*#ebf9fa;*/
}