/*paddings*/
.p-0{
    padding: 0
}
.p-1{
    padding: 1px;
}
.p-2{
    padding: 2px;
}
.p-3{
    padding: 3px;
}
.p-5{
    padding: 5px;
}
.p-7{
    padding: 7px;
}
.p-10{
    padding: 10px;
}
.p-15{
    padding: 15px;
}
.p-20{
    padding: 20px;
}
.p-25{
    padding: 25px;
}
.p-30{
    padding: 30px;
}
.p-35{
    padding: 35px;
}
.p-40{
    padding: 40px;
}
.p-45{
    padding: 45px;
}
.p-50{
    padding: 50px;
}
.p-60{
    padding: 60px;
}
.p-70{
    padding: 70px;
}
.p-80{
    padding: 80px;
}
.p-90{
    padding: 90px;
}
.p-100{
    padding: 100px;
}
/*top only paddings*/
.pt-0{
    padding-top: 0
}
.pt-1{
    padding-top: 1px;
}
.pt-2{
    padding-top: 2px;
}
.pt-3{
    padding-top: 3px;
}
.pt-5{
    padding-top: 5px;
}
.pt-7{
    padding-top: 7px;
}
.pt-10{
    padding-top: 10px;
}
.pt-15{
    padding-top: 15px;
}
.pt-20{
    padding-top: 20px;
}
.pt-25{
    padding-top: 25px;
}
.pt-30{
    padding-top: 30px;
}
.pt-35{
    padding-top: 35px;
}
.pt-40{
    padding-top: 40px;
}
.pt-45{
    padding-top: 45px;
}
.pt-50{
    padding-top: 50px;
}
.pt-60{
    padding-top: 60px;
}
.pt-70{
    padding-top: 70px;
}
.pt-80{
    padding-top: 80px;
}
.pt-90{
    padding-top: 90px;
}
.pt-100{
    padding-top: 100px;
}
/*bottom only paddings*/
.pb-0{
    padding-bottom: 0
}
.pb-1{
    padding-bottom: 1px;
}
.pb-2{
    padding-bottom: 2px;
}
.pb-3{
    padding-bottom: 3px;
}
.pb-5{
    padding-bottom: 5px;
}
.pb-7{
    padding-bottom: 7px;
}
.pb-10{
    padding-bottom: 10px;
}
.pb-15{
    padding-bottom: 15px;
}
.pb-20{
    padding-bottom: 20px;
}
.pb-25{
    padding-bottom: 25px;
}
.pb-30{
    padding-bottom: 30px;
}
.pb-35{
    padding-bottom: 35px;
}
.pb-40{
    padding-bottom: 40px;
}
.pb-45{
    padding-bottom: 45px;
}
.pb-50{
    padding-bottom: 50px;
}
.pb-60{
    padding-bottom: 60px;
}
.pb-70{
    padding-bottom: 70px;
}
.pb-80{
    padding-bottom: 80px;
}
.pb-90{
    padding-bottom: 90px;
}
.pb-100{
    padding-bottom: 100px;
}
/*left only paddings*/
.pl-0{
    padding-left: 0
}
.pl-1{
    padding-left: 1px;
}
.pl-2{
    padding-left: 2px;
}
.pl-3{
    padding-left: 3px;
}
.pl-5{
    padding-left: 5px;
}
.pl-7{
    padding-left: 7px;
}
.pl-10{
    padding-left: 10px;
}
.pl-15{
    padding-left: 15px;
}
.pl-20{
    padding-left: 20px;
}
.pl-25{
    padding-left: 25px;
}
.pl-30{
    padding-left: 30px;
}
.pl-35{
    padding-left: 35px;
}
.pl-40{
    padding-left: 40px;
}
.pl-45{
    padding-left: 45px;
}
.pl-50{
    padding-left: 50px;
}
.pl-60{
    padding-left: 60px;
}
.pl-70{
    padding-left: 70px;
}
.pl-80{
    padding-left: 80px;
}
.pl-90{
    padding-left: 90px;
}
.pl-100{
    padding-left: 100px;
}
/*right only paddings*/
.pr-0{
    padding-right: 0
}
.pr-1{
    padding-right: 1px;
}
.pr-2{
    padding-right: 2px;
}
.pr-3{
    padding-right: 3px;
}
.pr-5{
    padding-right: 5px;
}
.pr-7{
    padding-right: 7px;
}
.pr-10{
    padding-right: 10px;
}
.pr-15{
    padding-right: 15px;
}
.pr-20{
    padding-right: 20px;
}
.pr-25{
    padding-right: 25px;
}
.pr-30{
    padding-right: 30px;
}
.pr-35{
    padding-right: 35px;
}
.pr-40{
    padding-right: 40px;
}
.pr-45{
    padding-right: 45px;
}
.pr-50{
    padding-right: 50px;
}
.pr-60{
    padding-right: 60px;
}
.pr-70{
    padding-right: 70px;
}
.pr-80{
    padding-right: 80px;
}
.pr-90{
    padding-right: 90px;
}
.pr-100{
    padding-right: 100px;
}
/*top and bottom only paddings*/
.ptb-0{
    padding-top: 0;
    padding-bottom: 0;
}
.ptb-1{
    padding-top: 1px;
    padding-bottom: 1px;
}
.ptb-2{
    padding-top: 2px;
    padding-bottom: 2px;
}
.ptb-3{
    padding-top: 3px;
    padding-bottom: 3px;
}
.ptb-5{
    padding-top: 5px;
    padding-bottom: 5px;
}
.ptb-7{
    padding-top: 7px;
    padding-bottom: 7px;
}
.ptb-10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.ptb-15{
    padding-top: 15px;
    padding-bottom: 15px;
}
.ptb-20{
    padding-top: 20px;
    padding-bottom: 20px;
}
.ptb-25{
    padding-top: 25px;
    padding-bottom: 25px;
}
.ptb-30{
    padding-top: 30px;
    padding-bottom: 30px;
}
.ptb-35{
    padding-top: 35px;
    padding-bottom: 35px;
}
.ptb-40{
    padding-top: 40px;
    padding-bottom: 40px;
}
.ptb-45{
    padding-top: 45px;
    padding-bottom: 45px;
}
.ptb-50{
    padding-top: 50px;
    padding-bottom: 50px;
}
.ptb-60{
    padding-top: 60px;
    padding-bottom: 60px;
}
.ptb-70{
    padding-top: 70px;
    padding-bottom: 70px;
}
.ptb-80{
    padding-top: 80px;
    padding-bottom: 80px;
}
.ptb-90{
    padding-top: 90px;
    padding-bottom: 90px;
}
.ptb-100{
    padding-top: 100px;
    padding-bottom: 100px;
}
/*left and right only paddings*/
.plr-0{
    padding-left: 0;
    padding-right: 0;
}
.plr-1{
    padding-left: 1px;
    padding-right: 1px;
}
.plr-2{
    padding-left: 2px;
    padding-right: 2px;
}
.plr-3{
    padding-left: 3px;
    padding-right: 3px;
}
.plr-5{
    padding-left: 5px;
    padding-right: 5px;
}
.plr-7{
    padding-left: 7px;
    padding-right: 7px;
}
.plr-10{
    padding-left: 10px;
    padding-right: 10px;
}
.plr-15{
    padding-left: 15px;
    padding-right: 15px;
}
.plr-20{
    padding-left: 20px;
    padding-right: 20px;
}
.plr-25{
    padding-left: 25px;
    padding-right: 25px;
}
.plr-30{
    padding-left: 30px;
    padding-right: 30px;
}
.plr-35{
    padding-left: 35px;
    padding-right: 35px;
}
.plr-40{
    padding-left: 40px;
    padding-right: 40px;
}
.plr-45{
    padding-left: 45px;
    padding-right: 45px;
}
.plr-50{
    padding-left: 50px;
    padding-right: 50px;
}
.plr-60{
    padding-left: 60px;
    padding-right: 60px;
}
.plr-70{
    padding-left: 70px;
    padding-right: 70px;
}
.plr-80{
    padding-left: 80px;
    padding-right: 80px;
}
.plr-90{
    padding-left: 90px;
    padding-right: 90px;
}
.plr-100{
    padding-left: 100px;
    padding-right: 100px;
}
/*margins*/
.m-0{
    margin: 0
}
.m-1{
    margin: 1px;
}
.m-2{
    margin: 2px;
}
.m-3{
    margin: 3px;
}
.m-5{
    margin: 5px;
}
.m-7{
    margin: 7px;
}
.m-10{
    margin: 10px;
}
.m-15{
    margin: 15px;
}
.m-20{
    margin: 20px;
}
.m-25{
    margin: 25px;
}
.m-30{
    margin: 30px;
}
.m-35{
    margin: 35px;
}
.m-40{
    margin: 40px;
}
.m-45{
    margin: 45px;
}
.m-50{
    margin: 50px;
}
.m-60{
    margin: 60px;
}
.m-70{
    margin: 70px;
}
.m-80{
    margin: 80px;
}
.m-90{
    margin: 90px;
}
.m-100{
    margin: 100px;
}
/*top only margins*/
.mt-0{
    margin-top: 0
}
.mt-1{
    margin-top: 1px;
}
.mt-2{
    margin-top: 2px;
}
.mt-3{
    margin-top: 3px;
}
.mt-5{
    margin-top: 5px;
}
.mt-7{
    margin-top: 7px;
}
.mt-10{
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}
.mt-20{
    margin-top: 20px;
}
.mt-25{
    margin-top: 25px;
}
.mt-30{
    margin-top: 30px;
}
.mt-35{
    margin-top: 35px;
}
.mt-40{
    margin-top: 40px;
}
.mt-45{
    margin-top: 45px;
}
.mt-50{
    margin-top: 50px;
}
.mt-60{
    margin-top: 60px;
}
.mt-70{
    margin-top: 70px;
}
.mt-80{
    margin-top: 80px;
}
.mt-90{
    margin-top: 90px;
}
.mt-100{
    margin-top: 100px;
}
/*bottom only margins*/
.mb-0{
    margin-bottom: 0
}
.mb-1{
    margin-bottom: 1px;
}
.mb-2{
    margin-bottom: 2px;
}
.mb-3{
    margin-bottom: 3px;
}
.mb-5{
    margin-bottom: 5px;
}
.mb-7{
    margin-bottom: 7px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-15{
    margin-bottom: 15px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-25{
    margin-bottom: 25px;
}
.mb-30{
    margin-bottom: 30px;
}
.mb-35{
    margin-bottom: 35px;
}
.mb-40{
    margin-bottom: 40px;
}
.mb-45{
    margin-bottom: 45px;
}
.mb-50{
    margin-bottom: 50px;
}
.mb-60{
    margin-bottom: 60px;
}
.mb-70{
    margin-bottom: 70px;
}
.mb-80{
    margin-bottom: 80px;
}
.mb-90{
    margin-bottom: 90px;
}
.mb-100{
    margin-bottom: 100px;
}
/*left only margins*/
.ml-0{
    margin-left: 0
}
.ml-1{
    margin-left: 1px;
}
.ml-2{
    margin-left: 2px;
}
.ml-3{
    margin-left: 3px;
}
.ml-5{
    margin-left: 5px;
}
.ml-7{
    margin-left: 7px;
}
.ml-10{
    margin-left: 10px;
}
.ml-15{
    margin-left: 15px;
}
.ml-20{
    margin-left: 20px;
}
.ml-25{
    margin-left: 25px;
}
.ml-30{
    margin-left: 30px;
}
.ml-35{
    margin-left: 35px;
}
.ml-40{
    margin-left: 40px;
}
.ml-45{
    margin-left: 45px;
}
.ml-50{
    margin-left: 50px;
}
.ml-60{
    margin-left: 60px;
}
.ml-70{
    margin-left: 70px;
}
.ml-80{
    margin-left: 80px;
}
.ml-90{
    margin-left: 90px;
}
.ml-100{
    margin-left: 100px;
}
/*right only margins*/
.mr-0{
    margin-right: 0
}
.mr-1{
    margin-right: 1px;
}
.mr-2{
    margin-right: 2px;
}
.mr-3{
    margin-right: 3px;
}
.mr-5{
    margin-right: 5px;
}
.mr-7{
    margin-right: 7px;
}
.mr-10{
    margin-right: 10px;
}
.mr-15{
    margin-right: 15px;
}
.mr-20{
    margin-right: 20px;
}
.mr-25{
    margin-right: 25px;
}
.mr-30{
    margin-right: 30px;
}
.mr-35{
    margin-right: 35px;
}
.mr-40{
    margin-right: 40px;
}
.mr-45{
    margin-right: 45px;
}
.mr-50{
    margin-right: 50px;
}
.mr-60{
    margin-right: 60px;
}
.mr-70{
    margin-right: 70px;
}
.mr-80{
    margin-right: 80px;
}
.mr-90{
    margin-right: 90px;
}
.mr-100{
    margin-right: 100px;
}
/*top and bottom only margins*/
.mtb-0{
    margin-top: 0;
    margin-bottom: 0;
}
.mtb-1{
    margin-top: 1px;
    margin-bottom: 1px;
}
.mtb-2{
    margin-top: 2px;
    margin-bottom: 2px;
}
.mtb-3{
    margin-top: 3px;
    margin-bottom: 3px;
}
.mtb-5{
    margin-top: 5px;
    margin-bottom: 5px;
}
.mtb-7{
    margin-top: 7px;
    margin-bottom: 7px;
}
.mtb-10{
    margin-top: 10px;
    margin-bottom: 10px;
}
.mtb-15{
    margin-top: 15px;
    margin-bottom: 15px;
}
.mtb-20{
    margin-top: 20px;
    margin-bottom: 20px;
}
.mtb-25{
    margin-top: 25px;
    margin-bottom: 25px;
}
.mtb-30{
    margin-top: 30px;
    margin-bottom: 30px;
}
.mtb-35{
    margin-top: 35px;
    margin-bottom: 35px;
}
.mtb-40{
    margin-top: 40px;
    margin-bottom: 40px;
}
.mtb-45{
    margin-top: 45px;
    margin-bottom: 45px;
}
.mtb-50{
    margin-top: 50px;
    margin-bottom: 50px;
}
.mtb-60{
    margin-top: 60px;
    margin-bottom: 60px;
}
.mtb-70{
    margin-top: 70px;
    margin-bottom: 70px;
}
.mtb-80{
    margin-top: 80px;
    margin-bottom: 80px;
}
.mtb-90{
    margin-top: 90px;
    margin-bottom: 90px;
}
.mtb-100{
    margin-top: 100px;
    margin-bottom: 100px;
}
/*left and right only margins*/
.mlr-0{
    margin-left: 0;
    margin-right: 0;
}
.mlr-1{
    margin-left: 1px;
    margin-right: 1px;
}
.mlr-2{
    margin-left: 2px;
    margin-right: 2px;
}
.mlr-3{
    margin-left: 3px;
    margin-right: 3px;
}
.mlr-5{
    margin-left: 5px;
    margin-right: 5px;
}
.mlr-7{
    margin-left: 7px;
    margin-right: 7px;
}
.mlr-10{
    margin-left: 10px;
    margin-right: 10px;
}
.mlr-15{
    margin-left: 15px;
    margin-right: 15px;
}
.mlr-20{
    margin-left: 20px;
    margin-right: 20px;
}
.mlr-25{
    margin-left: 25px;
    margin-right: 25px;
}
.mlr-30{
    margin-left: 30px;
    margin-right: 30px;
}
.mlr-35{
    margin-left: 35px;
    margin-right: 35px;
}
.mlr-40{
    margin-left: 40px;
    margin-right: 40px;
}
.mlr-45{
    margin-left: 45px;
    margin-right: 45px;
}
.mlr-50{
    margin-left: 50px;
    margin-right: 50px;
}
.mlr-60{
    margin-left: 60px;
    margin-right: 60px;
}
.mlr-70{
    margin-left: 70px;
    margin-right: 70px;
}
.mlr-80{
    margin-left: 80px;
    margin-right: 80px;
}
.mlr-90{
    margin-left: 90px;
    margin-right: 90px;
}
.mlr-100{
    margin-left: 100px;
    margin-right: 100px;
}