.footer{
	flex-shrink: 0;
}

.footer a{
	letter-spacing: 1px;
	color: #0d81af;
}
.footer a:hover{
	color: #026992;
	text-decoration: underline;
}
@media only screen and (max-width:640px) {
	
}
