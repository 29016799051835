/* backgrounds */
.bg-light-blue{
	background: #4FA4D3;
}
.bg-blue{
	background: #0062c4;
}
.bg-dark-blue{
	background: #0E3151 ;
}
.bg-button-blue{
	background: #0060bf;
}
.bg-avw-blue{
	background: #439ECA ;
}
.bg-light-blue{
	background: #e0f1fa ;
}
.bg-green{
	background: green;
}
.bg-light-green{
	background: #10A37F;
}
.bg-light-red{
	background: #f9c9ce;
}
.bg-red{
	background: #F12E45;
}
.bg-dark-red{
	background: #c82d1f;
}
.bg-gold{
	background: gold;
}
.bg-light-gold{
	background: gold;
}

/* text */
.txt-light-blue{
	color: #4FA4D3;
}
.txt-blue{
	color: #0062c4;
}
.txt-dark-blue{
	color: #0E3151 ;
}
.txt-green{
	color: #10A37F;
}
.txt-avw-green{
	color: #49939F;
}
.txt-avw-dark-green{
	color: #356973;/*#204146;*/
}
.txt-light-red{
	color: #f4acb4;
}
.txt-red{
	color: #F12E45;
}
.txt-dark-red{
	color: #c82d1f;
}

/* text social media */
.txt-facebook{
	color: #3b5998
}
.txt-twitter{
	color: #1dcaff
}
.txt-instagram{
	color: #E4405F
}
.txt-pinterest{
	color: #c8232c
}