.font-source-sans{
	font-family: 'Source Sans Pro', sans-serif;
}
.font-raleway{
	font-family: 'Raleway', sans-serif;
}

.font-v-large{
    font-size: 50px;
    line-height: 100%;
}
.font-iv-large{
    font-size: 40px;
    line-height: 100%;
}
.font-xxx-large{
    font-size: 30px;
    line-height: 100%;
}
.font-xx-large{
    font-size: 24px;
    line-height: 100%;
}
.font-x-large{
    font-size: 20px;
    line-height: 100%;
}
.font-large{
    font-size: 18px;
    line-height: 100%;
}
.font-large-medium-mr{
    font-size: 18px;
    line-height: 100%;
}
.font-medium{
    font-size: 16px;
    line-height: 100%;
}
.font-standard{
    font-size: 14px;
    line-height: 100%;
}
.font-small{
    font-size: 12px;
    line-height: 100%;
}
.font-tiny{
    font-size: 11px;
    line-height: 100%;
}
.font-x-tiny{
    font-size: 10px;
    line-height: 100%;
}

.font-weight-100{
	font-weight: 100;
}
.font-weight-200{
	font-weight: 200;
}
.font-weight-300{
	font-weight: 300;
}
.font-weight-400{
	font-weight: 400;
}
.font-weight-500{
	font-weight: 500;
}
.font-weight-600{
	font-weight: 600;
}
.font-weight-700{
	font-weight: 700;
}
.font-weight-800{
	font-weight: 800;
}

.line-height-100per{
	line-height: 100%;
}
.line-height-125per{
	line-height: 125%;
}
.line-height-150per{
	line-height: 150%;
}
.line-height-175per{
	line-height: 175%;
}

.italic {
    font-style: italic
}
.bold{
	font-weight: bold
}
.uppercase{
	text-transform: uppercase;
}
.lowercase{
	text-transform: lowercase;
}
.letter-spacing-0px{
	letter-spacing: 0;
}
.letter-spacing-1px{
	letter-spacing: 1px;
}
.underline{
	text-decoration: underline;
}
.overline{
	text-decoration: overline;
}
.line-through{
	text-decoration: line-through;
}
.bright-125 { 
	filter: brightness(1.25);
}
.bright-150 { 
	filter: brightness(1.5);
}
.bright-175 { 
	filter: brightness(1.75);
}
.justify {
	text-align: justify;
}

@media only screen and (max-width:640px) {
	.font-large-medium-mr{
		font-size: 14px;
		line-height: 100%;
	}
}
@media only screen and (max-width:480px) {
}
@media only screen and (max-width:380px) {
}