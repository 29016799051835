/* FORMS */
.input-group {
  margin-bottom: 10px;
}
.input-group.no-bottom-margin{
  margin-bottom: 0;
  margin: 0;
}
label{
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 500;
	font-size: 14px important!;
}
label select{
  margin: 0;
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus{
	color: #000;
	font-family: 'Raleway', sans-serif;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 500;
	font-size: 14px important!;
	line-height: 100%;
	padding: 10px 7px;
	height: 40px;
	margin: 0 0 0 0;
	margin-bottom: 0;
    background: #fff;
    border: 1px solid #ccc;
    
  outline: none;
  vertical-align: baseline;
  -webkit-box-sizing: none;
          box-sizing: none;
    box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  transition: none important!;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
}
    
input[type="radio"]{
	padding: 10px;
	margin: 0;
}
select,
select option{
	color: #333;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 500;
	font-size: 8px important!;
	
}
select[multiple]{
	height: 150px;
	
}

textarea {
  height: auto;
  min-height: 100px !important;
	margin: 0 !important;
}


input:hover,
input[type="text"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
input[type="number"]:hover,
select:hover,
textarea:hover{
	border: 1px solid #aaa;
	box-shadow: 0 0 3px #cacaca;
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus{
	border: 1px solid #777;
	box-shadow: 0 0 5px #cacaca;
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	font-family: 'Source Sans Pro', sans-serif;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 100%;
	background: inherit;
	color: #aaa;
	border-color: transparent;
	opacity: 1;
}
input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	font-family: 'Raleway', sans-serif;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 100%;
	background: #fefefe;
	color: #aaa;
	border-color: transparent;
	opacity: 1;
}
input::placeholder,
select::placeholder,
textarea::placeholder {
	font-family: 'Raleway', sans-serif;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 100%;
	background: inherit;
	color: #aaa;
	border-color: transparent;
	opacity: 1;
}

input:disabled, input[readonly]:disabled,
input[type="text"]:disabled,
input[type="email"]:disabled,
input[type="password"]:disabled,
input[type="number"]:disabled,
select:disabled,
textarea:disabled, textarea[readonly] {
	background-color: #ddd;
	background: #ddd;
	cursor: not-allowed;
}

input.medium,
input[type="text"].medium,
input[type="email"].medium,
input[type="password"].medium,
input[type="number"].medium{
	padding: 5px;
	width: 200px;
	height: 40px;
}
input.small,
input[type="text"].small,
input[type="email"].small,
input[type="password"].small,
input[type="number"].small{
	padding: 7px;
	width: 40px;
	height: auto;
}
input.small.width-auto,
input[type="text"].small.width-auto,
input[type="email"].small.width-auto,
input[type="password"].small.width-auto,
input[type="number"].small.width-auto{
	padding: 7px;
	width: auto;
	height: auto;
}
input.small.width-200px,
input[type="text"].small.width-200px,
input[type="email"].small.width-200px,
input[type="password"].small.width-200px,
input[type="number"].small.width-200px{
	padding: 7px;
	width: 200px;
	height: auto;
}
input.small.width-100per,
input[type="text"].small.width-100per,
input[type="email"].small.width-100per,
input[type="password"].small.width-100per,
input[type="number"].small.width-100per{
	padding: 7px;
	width: 100%;
	height: auto;
}
input.tiny,
input[type="text"].tiny,
input[type="email"].tiny,
input[type="password"].tiny,
input[type="password"].tiny{
	padding: 0 5px 0 3px;
	margin: 0;
	height: 25px;
	width: 55px;
}
input.tiny-medium,
input[type="text"].tiny-medium,
input[type="email"].tiny-medium,
input[type="password"].tiny-medium,
input[type="number"].tiny-medium{
	padding: 0 5px 0 5px;
	margin: 0;
	height: 26px;
	width: 175px;
}
input.donation{
	width: 70px;
}
input.uppercase,
input[type="text"].uppercase,
input[type="email"].uppercase,
input[type="password"].uppercase,
textarea.uppercase{
    text-transform: uppercase;
}

select.small.width-50px,
select.small.width-35px{
	padding: 5px 0px 5px 20px;
	width: 50px;
  height: 35px;
  margin-bottom: 5px;
}
select.small.width-35px{
	padding: 5px 0px 5px 12px;
	width: 35px;
}
/*ERROR*/

/* RADIO */
input[type='radio'] + label {
  margin: 0;
  padding: 0 15px 0 5px;
  height: 30px;
	font-family: 'Oswald', sans-serif;
	font-weight: 300;
	font-size: 14px;
}
/* Base styling for the radio button */
input[type='radio'].custom-radio {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  transform: scale(1.5); /* Increase the size proportionally */
  appearance: none; /* Remove default browser styles */
  border: 2px solid #ccc; /* Default border */
  border-radius: 50%; /* Make it circular */
  outline: none;
  cursor: pointer;
  position: relative;
}

/* Add a visual indicator when selected */
input[type='radio'].custom-radio:checked {
  border-color: #026992; /* Change border color when selected */
  background-color: #026992; /* Add a background color when selected */
}

/* Optional: Add a dot inside the selected radio */

input[type='radio'].custom-radio:checked::before {
  content: '';
  width: 7px;
  height: 7px;
  background-color: white; /* Dot color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/** CHECKBOX **/
input[type='checkbox'] {
  margin: 0;
  padding: 0;
  opacity: 0;
}
/* set default styles for the checkboxes and radio buttons */
.checkbox {
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: 0 10px 0 -12px;
  padding: 0;
  cursor: pointer;
  position: relative;
}
input[type='checkbox'] + label {
  margin: 0;
  padding: 0;
  height: 35px;
}
input[type='checkbox'] + label span.checkbox {
  background: #fff;
  border: 1px solid #ccc;
  margin-right: 0;
}
input[type='checkbox'].bg-error + label span.checkbox {
	background: #ed1c24;
	color: #fff;
	border-color: transparent;
}
input[type='checkbox'] + label span.message {
  position: relative;
  top: -10px;
  left: 0;
  width: auto;
}
input[type='checkbox'] + label span.checkbox:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 6px;
  width: 6px;
  height: 12px;
  background: transparent;
  z-index: 1;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  transform: rotate(-44deg);
  border-radius: 2px;
}
input[type='checkbox'] + label span.checkbox:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 14px;
  width: 6px;
  height: 20px;
  background: transparent;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
}
input[type='checkbox']:hover + label span.checkbox {
	border: 1px solid #aaa;
	box-shadow: 0 0 3px #cacaca;
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
input[type='checkbox']:focus + label span.checkbox {
	border: 1px solid #777;
	box-shadow: 0 0 5px #cacaca;
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
input[type='checkbox']:hover + label span.checkbox:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 6px;
  width: 6px;
  height: 14px;
  background: #ddd;
  z-index: 1;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  transform: rotate(-44deg);
  border-radius: 2px;
}
input[type='checkbox']:hover + label span.checkbox:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 17px;
  width: 6px;
  height: 27px;
  background: #ddd;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
}
input[type='checkbox']:checked + label span.checkbox {
  border: 1px solid #aaa;
}
input[type='checkbox']:checked + label span.checkbox:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 6px;
  width: 6px;
  height: 14px;
  background:#026992;
  z-index: 1;
  -webkit-transform: rotate(-44deg);
  -moz-transform: rotate(-44deg);
  transform: rotate(-44deg);
  border-radius: 2px;
}
input[type='checkbox']:checked + label span.checkbox:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 17px;
  width: 6px;
  height: 27px;
  background: #026992;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
}

.checkbox.medium {
  width: 25px;
  height: 25px;
  margin: 0;
  margin: 0 5px 0 -13px;
}
input[type='checkbox'] + label.medium {
  height: 25px;
  width: auto;
}
input[type='checkbox'] + label span.message.medium {
  top: -5px;
}
input[type='checkbox'] + label span.checkbox.medium:after {
  top: 8px;
  left: 4px;
  width: 4px;
  height: 8px;
}
input[type='checkbox'] + label span.checkbox.medium:before {
  top: 3px;
  left: 9px;
  width: 4px;
  height: 13px;
}
input[type='checkbox']:hover + label span.checkbox.medium:after {
  top: 10px;
  left: 4px;
  width: 4px;
  height: 10px;
}
input[type='checkbox']:hover + label span.checkbox.medium:before {
  top: 2px;
  left: 13px;
  width: 3px;
  height: 18px;
}
input[type='checkbox']:checked + label span.checkbox.medium:after {
  top: 10px;
  left: 4px;
  width: 3px;
  height: 10px;
}
input[type='checkbox']:checked + label span.checkbox.medium:before {
  top: 2px;
  left: 13px;
  width: 3px;
  height: 18px;
}

.checkbox.small {
  width: 20px;
  height: 20px;
  margin: 0;
  margin: 0 5px 0 -13px;
}
input[type='checkbox'] + label.small {
  height: 20px;
  width: auto;
}
input[type='checkbox'] + label span.message.small {
  top: -5px;
}
input[type='checkbox'] + label span.checkbox.small:after {
  top: 8px;
  left: 4px;
  width: 4px;
  height: 8px;
}
input[type='checkbox'] + label span.checkbox.small:before {
  top: 3px;
  left: 9px;
  width: 4px;
  height: 13px;
}
input[type='checkbox']:hover + label span.checkbox.small:after {
  top: 8px;
  left: 4px;
  width: 4px;
  height: 8px;
}
input[type='checkbox']:hover + label span.checkbox.small:before {
  top: 3px;
  left: 9px;
  width: 4px;
  height: 13px;
}
input[type='checkbox']:checked + label span.checkbox.small:after {
  top: 8px;
  left: 4px;
  width: 4px;
  height: 8px;
}
input[type='checkbox']:checked + label span.checkbox.small:before {
  top: 3px;
  left: 9px;
  width: 4px;
  height: 13px;
}


/* Disabled Checkbox Styling */
input[type='checkbox']:disabled + label span.checkbox {
  background: #aaa; /* Light gray for disabled background */
  border: 1px solid #7777; /* Gray border for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled */
  opacity: 0.6; /* Slightly transparent for disabled appearance */
}

input[type='checkbox']:disabled + label span.message {
  color: #777; /* Light gray text for disabled message */
}

/* Retain checked state visual styles for disabled checkboxes */
input[type='checkbox']:checked:disabled + label span.checkbox:after,
input[type='checkbox']:checked:disabled + label span.checkbox:before {
  background: #7777; /* Grayish checkmark for disabled state */
}

/* Override hover styles for disabled checkboxes */
input[type='checkbox']:disabled:hover + label span.checkbox {
  border: 1px solid #777; /* No hover effect */
  box-shadow: none;
}

/* Ensure focus styles don't apply to disabled checkboxes */
input[type='checkbox']:disabled:focus + label span.checkbox {
  border: 1px solid #777; /* No focus effect */
  box-shadow: none;
}

/* Disabled Checkbox Styling */
input[type='checkbox'].disabled + label span.checkbox {
  background: #f9c9ce; /* Light gray for disabled background */
  border: 1px solid #7777; /* Gray border for disabled state */
  cursor: not-allowed; /* Not-allowed cursor for disabled */
  opacity: 0.6; /* Slightly transparent for disabled appearance */
}

input[type='checkbox'].disabled + label span.message {
  color: #777; /* Light gray text for disabled message */
}

/* Retain checked state visual styles for disabled checkboxes */
input[type='checkbox']:checked.disabled + label span.checkbox:after,
input[type='checkbox']:checked.disabled + label span.checkbox:before {
  background: #7777; /* Grayish checkmark for disabled state */
}

/* Override hover styles for disabled checkboxes */
input[type='checkbox'].disabled:hover + label span.checkbox {
  border: 1px solid #777; /* No hover effect */
  box-shadow: none;
}

/* Ensure focus styles don't apply to disabled checkboxes */
input[type='checkbox'].disabled:focus + label span.checkbox {
  border: 1px solid #777; /* No focus effect */
  box-shadow: none;
}

/* ROP ZONE */
.dropzone{
	opacity: 0.5;
}

.dropzone:hover{
	opacity: 1.0;
}