/* widths */
.width-5px{
	width: 5px;
}
.width-10px{
	width: 10px;
}
.width-15px{
	width: 15px;
}
.width-20px{
	width: 20px;
}
.width-25px{
	width: 25px;
}
.width-30px{
	width: 30px;
}
.width-40px{
	width: 40px;
}
.width-50px{
	width: 50px;
}
.width-60px{
	width: 60px;
}
.width-70px{
	width: 70px;
}
.width-75px{
	width: 75px;
}
.width-80px{
	width: 80px;
}
.width-90px{
	width: 90px;
}
.width-100px{
	width: 100px;
}
.width-110px{
	width: 110px;
}
.width-120px{
	width: 120px;
}
.width-125px{
	width: 125px;
}
.width-130px{
	width: 130px;
}
.width-140px{
	width: 140px;
}
.width-150px{
	width: 150px;
}
.width-175px{
	width: 170px;
}
.width-200px{
	width: 200px;
}
.width-300px{
	width: 300px;
}
.width-400px{
	width: 400px;
}
.width-500px{
	width: 500px;
}
.width-1000px{
	width: 1000px;
}
.width-100per{
	width: 100%;
}
.width-50per{
	width: 50%;
}
.width-auto{
	width: auto;
}

/* heights */
.height-5px{
	height: 5px;
}
.height-10px{
	height: 10px;
}
.height-15px{
	height: 15px;
}
.height-20px{
	height: 20px;
}
.height-25px{
	height: 25px;
}
.height-30px{
	height: 30px;
}
.height-40px{
	height: 40px;
}
.height-50px{
	height: 50px;
}
.height-60px{
	height: 60px;
}
.height-70px{
	height: 70px;
}
.height-75px{
	height: 75px;
}
.height-80px{
	height: 80px;
}
.height-90px{
	height: 90px;
}
.height-100px{
	height: 100px;
}
.height-125px{
	height: 125px;
}
.height-150px{
	height: 150px;
}
.height-175px{
	height: 170px;
}
.height-200px{
	height: 200px;
}
.height-300px{
	height: 300px;
}
.height-400px{
	height: 400px;
}
.height-500px{
	height: 500px;
}
.height-1000px{
	height: 1000px;
}
.height-100per{
	height: 100%;
}
.height-auto{
	height: auto;
}