.sticky-top{
	position: fixed;
	left:0;
	top:0;
	right:0;	
}
.sticky-bottom{
	position: fixed;
	left:0;
	bottom:0;
	right:0;	
}
/* on canvas */
.nav-bar {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background: transparent;
	height: 70px;
	margin-bottom: 0;
	padding: 0;
}
.nav-bar-left{
	width: 50%;
}
.nav-bar-center{
	width: 0;
}
.nav-bar-right{
	width: 50%;
}

@media only screen and (max-width:1024px) {
}
@media only screen and (max-width:780px) {
}
@media only screen and (max-width:720px) {
}
@media only screen and (max-width:680px) {
}
@media screen and (max-width: 63.9375em) {
}
@media screen and (max-width: 39.9375em) {
	.nav-bar-left{
		width: 80%;
	}
	.nav-bar-right{
		width: 20%;
	}
	.nav-bar {
		height: 50px;
	}
}
@media only screen and (max-width:40em) {
}