/*HIDE & SHOW*/
.hide-for-240px{
	display: inline;
}
.show-for-240px{
	display: none;
}
.hide-for-280px{
	display: inline;
}
.show-for-280px{
	display: none;
}
.hide-for-320px{
	display: inline;
}
.show-for-320px{
	display: none;
}
.hide-for-380px{
	display: inline;
}
.show-for-380px{
	display: none;
}
.hide-for-420px{
	display: inline;
}
.show-for-420px{
	display: none;
}
.hide-for-480px{
	display: inline;
}
.show-for-480px{
	display: none;
}
.hide-for-520px{
	display: inline;
}
.show-for-520px{
	display: none;
}
.hide-for-580px{
	display: inline;
}
.show-for-580px{
	display: none;
}
.hide-for-640px{
	display: inline;
}
.show-for-640px{
	display: none;
}
.hide-for-680px{
	display: inline;
}
.show-for-680px{
	display: none;
}
.hide-for-720px{
	display: inline;
}
.show-for-720px{
	display: none;
}
.hide-for-780px{
	display: inline;
}
.show-for-780px{
	display: none;
}
.hide-for-820px{
	display: inline;
}
.show-for-820px{
	display: none;
}
.hide-for-880px{
	display: inline;
}
.show-for-880px{
	display: none;
}
.hide-for-920px{
	display: inline;
}
.show-for-920px{
	display: none;
}
@media only screen and (max-width:240px) {
	.hide-for-240px{
		display: none;
	}
	.show-for-240px{
		display: inline;
	}
}
@media only screen and (max-width:280px) {
	.hide-for-280px{
		display: none;
	}
	.show-for-280px{
		display: inline;
	}
}
@media only screen and (max-width:320px) {
	.hide-for-320px{
		display: none;
	}
	.show-for-320px{
		display: inline;
	}
}
@media only screen and (max-width:380px) {
	.hide-for-380px{
		display: none;
	}
	.show-for-380px{
		display: inline;
	}
}
@media only screen and (max-width:420px) {
	.hide-for-420px{
		display: none;
	}
	.show-for-420px{
		display: inline;
	}
}
@media only screen and (max-width:480px) {
	.hide-for-480px{
		display: none;
	}
	.show-for-480px{
		display: inline;
	}
}
@media only screen and (max-width:520px) {
	.hide-for-520px{
		display: none;
	}
	.show-for-520px{
		display: inline;
	}
}
@media only screen and (max-width:580px) {
	.hide-for-580px{
		display: none;
	}
	.show-for-580px{
		display: inline;
	}
}
@media only screen and (max-width:640px) {
	.hide-for-640px{
		display: none;
	}
	.show-for-640px{
		display: inline;
	}
}
@media only screen and (max-width:720px) {
	.hide-for-720px{
		display: none;
	}
	.show-for-720px{
		display: inline;
	}
}
@media only screen and (max-width:780px) {
	.hide-for-780px{
		display: none;
	}
	.show-for-780px{
		display: inline;
	}
}
@media only screen and (max-width:820px) {
	.hide-for-820px{
		display: none;
	}
	.show-for-820px{
		display: inline;
	}
}
@media only screen and (max-width:880px) {
	.hide-for-880px{
		display: none;
	}
	.show-for-880px{
		display: inline;
	}
}
@media only screen and (max-width:920px) {
	.hide-for-920px{
		display: none;
	}
	.show-for-920px{
		display: inline;
	}
}